
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'clients-list',
    loadChildren: () => import('./clients/clients-list/clients-list.module').then( m => m.ClientsListPageModule)
  },
  {
    path: 'orders-list',
    loadChildren: () => import('./orders/orders-list/orders-list.module').then( m => m.OrdersListPageModule)
  },
  {
    path: 'historicalorders-list',
    loadChildren: () => import('./orders/historicalorders-list/historicalorders-list.module').then( m => m.HistoricalordersListPageModule)
  },
  {
    path: 'items-list',
    loadChildren: () => import('./items/items-list/items-list.module').then( m => m.ItemsListPageModule)
  },
  {
    path: 'profile-saw',
    loadChildren: () => import('./profile/profile-saw/profile-saw.module').then( m => m.ProfileSawPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },

  {
    path: 'domicilios-list',
    loadChildren: () => import('./domiclilios/domicilios-list/domicilios-list.module').then( m => m.DomiciliosListPageModule)
  },
  {
    path: 'items-add',
    loadChildren: () => import('./items/items-add/items-add.module').then( m => m.ItemsAddPageModule)
  },
  {
    path: 'historicalorders-list',
    loadChildren: () => import('./orders/historicalorders-list/historicalorders-list.module').then( m => m.HistoricalordersListPageModule)
  },  {
    path: 'orders-send',
    loadChildren: () => import('./orders/orders-send/orders-send.module').then( m => m.OrdersSendPageModule)
  },
  {
    path: 'selection',
    loadChildren: () => import('./login/selection/selection.module').then( m => m.SelectionPageModule)
  },
  {
    path: 'orders-edit',
    loadChildren: () => import('./orders/orders-edit/orders-edit.module').then( m => m.OrdersEditPageModule)
  },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
